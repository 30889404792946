<template>
  <div class="container-fluid broadcast-container">
    <div class="row">
      <div class="col"></div>
      <div class="col-12 col-md-9">
        <Video :email="email"
               :educatorId="educatorId"/>
      </div>
      <div class="col"></div>
    </div>

    <v-dialog></v-dialog>
  </div>
</template>

<script>
  const Video = () => import('@/components/user/broadcast/Video.vue');

  export default {
    name : 'Broadcast',
    data() {
      return {
        validRoom : false,
      }
    },
    components : {
      // eslint-disable-next-line vue/no-unused-components
      Video,
    },
    computed : {
      email() {
        return this.$store.getters['user/user'].email;
      },
      educatorId() {
        return this.$store.getters['user/user'].user_id;
      },
    },
    created(){
      this.checkRoom();
    },
    mounted() {},
    methods : {

      /**
       * Check Broadcast Room
       */
      checkRoom (){
        this.$http.get('api/broadcast/check_room', {
          params : {
            name : this.$route.params.roomName,
          },
        }).then(response => {
          const that = this;

          if(!response.data){
            that.$modal.show('dialog', {
              title   : "Ooops! Something went wrong.",
              text    : "You seem to be in the wrong room",
              buttons : [{
                title   : 'CLOSE',
                handler : () => {
                  this.$modal.hide('dialog');
                  that.$router.push('/browse/influencer');
                },
              }],
            });
          }
        });
      },

    },
  }
</script>

<style scoped>
</style>
